import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'
import NewsletterForm from '@components/forms/newsletterform'
import Headline from '@objects/headline'
import Copy from '@objects/copy'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  inner: {
    position: 'relative',
  },
  copy: {
    ...theme.typography.teaser,
    marginBottom: theme.spacing(7.5),
    color: theme.palette.grey.dark,
    textAlign: 'center',
  },
  illustration: {
    position: 'absolute',
    width: '120px',
    bottom: theme.spacing(-27),
    transform: 'rotate(180deg)',
    left: theme.spacing(-8),
    [theme.breakpoints.up('md')]: {
      width: '266px',
      left: theme.spacing(-15),
      bottom: theme.spacing(-36),
    },
    [theme.breakpoints.up('lg')]: {
      bottom: theme.spacing(-40),
    },
    [theme.breakpoints.up('xl')]: {
      left: theme.spacing(-52),
    },
  },
}))

function NewsletterTeaser({ title, copy }) {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Container
      className={classes.root}
      background={theme.palette.background.grey}
      padded
      data-testid={'newsletter-teaser'}
      id={'newsletter-teaser'}
      ariaLabel="arialabel.newsletterteaser"
    >
      <div className={classes.inner}>
        <img
          className={classes.illustration}
          src="/img/illustration/illustration_colored_diagonal.svg"
          alt=""
        />
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            {title && (
              <Headline level={2} align="center">
                {title}
              </Headline>
            )}
            {copy && <Copy className={classes.copy}>{copy}</Copy>}
            <NewsletterForm />
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

NewsletterTeaser.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
}

export default NewsletterTeaser
