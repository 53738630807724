import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const FocusFieldError = () => {
  const { isSubmitting, isValid } = useFormikContext()

  useEffect(() => {
    if (isValid) return

    document.querySelector('.Mui-error input')?.focus()
  }, [isSubmitting])

  return null
}

export default FocusFieldError
