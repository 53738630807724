import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import subscribeNewsletter from '@api/subscribeNewsletter'

import ToggleComponentContext from '@providers/toggleComponentProvider'

import Button from '@objects/button'
import FriendlyCaptcha from '@objects/formFields/friendlyCaptcha'
import TextField from '@objects/formFields/textField'
import CheckboxField from '@objects/formFields/checkboxField'

import FocusFieldError from '@components/forms/focusFieldError'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(7, 'auto', 0, 'auto'),
    display: 'inherit',
  },
}))

function NewsletterForm() {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const validationSchema = Yup.object({
    firstname: Yup.string().required(
      intl.formatMessage({ id: 'newsletter.firstname.error.required' })
    ),
    lastname: Yup.string().required(
      intl.formatMessage({ id: 'newsletter.lastname.error.required' })
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'newsletter.email.error' }))
      .required(intl.formatMessage({ id: 'newsletter.email.error.required' })),
    termsOfPrivacy: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({ id: 'newsletter.error.required' })
    ),
    friendlyCaptchaCode: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'contact.error.required' })),
  })

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    termsOfPrivacy: false,
    friendlyCaptchaCode: '',
  }

  function _handleSubmit(values, { resetForm, setSubmitting }) {
    subscribeNewsletter(values)
      .then((response) => {
        if (response.status === 202) {
          resetForm()
          toggleOverlay(true, 'newsletterSuccess', 'default', {
            trackingID: 'newsletterSuccess',
            width: 'sm',
            onAcceptance: () => toggleOverlay(false),
          })
        } else {
          toggleOverlay(true, 'newsletterError', 'default', {
            trackingID: 'newsletterError',
            width: 'sm',
            onAcceptance: () => toggleOverlay(false),
          })
        }
        setSubmitting(false)
      })
      .catch((err, test) => {
        toggleOverlay(true, 'newsletterError', 'default', {
          trackingID: 'newsletterError',
          width: 'sm',
          onAcceptance: () => toggleOverlay(false),
        })
        setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form id="newsletter">
          <FocusFieldError />
          <Grid container spacing={5} justify="center">
            <Grid item xs={12} md={6}>
              <TextField
                id="firstname-input"
                name="firstname"
                fullWidth
                variant="outlined"
                label={intl.formatMessage({
                  id: 'newsletter.firstname.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'newsletter.firstname.placeholder',
                })}
                autoComplete="given-name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="lastname-input"
                name="lastname"
                fullWidth
                variant="outlined"
                label={intl.formatMessage({
                  id: 'newsletter.lastname.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'newsletter.lastname.placeholder',
                })}
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                data-testid="mail-input"
                id="email-input"
                type="email"
                name="email"
                fullWidth
                variant="outlined"
                label={intl.formatMessage({
                  id: 'newsletter.email.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'newsletter.email.placeholder',
                })}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <CheckboxField
                id="policy-checkbox"
                name="termsOfPrivacy"
                labelId="newsletter.privacy.label"
                labelLink="dataprivacy.path"
              />
              <FriendlyCaptcha name="friendlyCaptchaCode" background="gray" />
            </Grid>
            <Grid item xs={12} md={10}>
              <Button
                className={classes.button}
                type="secondary"
                color="red"
                disabled={isSubmitting}
                formSubmit
              >
                {intl.formatMessage({ id: 'newsletter.button' })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

NewsletterForm.propTypes = {}

export default NewsletterForm
