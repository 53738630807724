import React from 'react'
import PropTypes from 'prop-types'

import Container from '@objects/container'
import NewsletterTeaser from '@components/newsletterTeaser'
import Stage from '@components/stage'
import useStaticModule from '@hooks/useStaticModule'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import Copy from '@objects/copy'
import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(10),
  },
}))

function NewsletterUnsubscribePage({ pageContext }) {
  const classes = useStyles()
  const intl = useIntl()
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)
  const copy = getStaticFieldValue(
    'newsletter.unsubscribe.copy',
    'newsletter.unsubscribe.copy.text'
  )

  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title={pageContext.page.title}
        ariaLabel={pageContext.page.title}
      />

      <Container width="sm" ariaLabel="arialabel.abmeldebestaetigung">
        <Copy type="article" html={copy} component="div" />
        <Button className={classes.button} to="/">
          <FormattedMessage id="button.tohome" />
        </Button>
      </Container>
      <NewsletterTeaser
        title={intl.formatMessage({ id: 'newsletter.hl' })}
        copy={intl.formatMessage({ id: 'newsletter.copy' })}
      />
    </>
  )
}

NewsletterUnsubscribePage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default NewsletterUnsubscribePage
